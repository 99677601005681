const util = {
    
    initOT: function (REACT_APP_OT_DOMAIN) { 
        if(REACT_APP_OT_DOMAIN) {
            const script = document.createElement('script');
            script.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
            script.type = "text/javascript"
            script.setAttribute('data-domain-script', REACT_APP_OT_DOMAIN);
    
            const functionScript = document.createElement('script');
            functionScript.type = "text/javascript"
            functionScript.innerHTML = "function OptanonWrapper() { }"
    
            document.head.appendChild(script);
            document.head.appendChild(functionScript);
        }
    },

    initGTM: function(GTM_CONTAINER) {
        if (GTM_CONTAINER) {
            const GTMScript = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_CONTAINER}');
            `

            const script = document.createElement('script');
            script.innerHTML = GTMScript;
            document.head.appendChild(script);
        }
    },

    getValidValue(variable) {
        return variable !== "0" ? variable : ""
    }
    
}


export default util