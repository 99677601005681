import React, {useState, useEffect, useCallback} from "react";
import "./styles.scss"
import util from "../../helpers/util";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GROUPE_LOCCITANE } from "../../assets/variables";


function Search (props) {
  const getValidValue = util.getValidValue;

  const [showAdvanced, setShowAdvanced] = useState(false)
  const [title, setTitle] = useState('');
  const [filters, setFilters] = useState({departments: [], states: [], countries: [], types: []});
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [queryType, setQueryType] = useState("");
  const [queryDepartment, setQueryDepartment] = useState("");
  const [queryState, setQueryState] = useState("");
  const [queryCountry, setQueryCountry] = useState(0);


  const setDefaultState = useCallback(() => {
        setQueryState(searchParams.get('state') ? searchParams.get('state') : "");
        setQueryCountry(searchParams.get('country_id') ? searchParams.get('country_id') : "");
        setQueryType(searchParams.get('type') ? searchParams.get('type') : "")
        setQueryDepartment(searchParams.get('department') ? searchParams.get('department') : "");
  },  [searchParams])

  useEffect(() => {
    let filtersUrl = "/getFilters";
    let brand = props.companyPage != GROUPE_LOCCITANE ? props.companyPage : "";
    let filters = {brand: brand};
    if(searchParams.get('country_id')) {
      filters.country_id = searchParams.get('country_id');
    }
     
    const urlParam = new URLSearchParams(filters);
 
    filtersUrl += `?${urlParam.toString()}`;

    fetch(filtersUrl)
      .then((res) => res.json())
      .then((data) => {
        setFilters(data)
    });

    setDefaultState()

  },  [searchParams, setDefaultState])



  const handleSubmit = useCallback((dynamicSubmitValue) =>  {
      let filter = {
        type: getValidValue(queryType) ,
        department: getValidValue(queryDepartment),
        country_id: getValidValue(queryCountry),
        state: getValidValue(queryState),
        title: title,
        brand: (props.companyPage && props.companyPage !== GROUPE_LOCCITANE) ? props.companyPage : ''
      };

      if(dynamicSubmitValue) {
        filter[dynamicSubmitValue.id] = getValidValue(dynamicSubmitValue.value)
      }

      const urlParam = new URLSearchParams(filter);
      if(searchParams.toString() !== urlParam.toString()) {
        if(props.companyPage) {
          navigate(`/${props.companyPage}?${urlParam.toString()}`);
        } else {
          navigate(`?${urlParam.toString()}`);
        }
      }
 

  }, [navigate, props.companyPage, title, getValidValue, queryCountry, 
      queryDepartment, queryState, queryType, searchParams])

 
  const handleAdvanced = () => {
    setShowAdvanced(!showAdvanced)
  }


  return  (
  <div className="container">
    <form className="searchForm" onSubmit={(e) => {e.preventDefault(); handleSubmit()}}>
      <div className="searchForm__globalSearch m-form-row container">
          <div className="m-field-wrapper select">
              <div className="m-form-field">
                  <input name="title" placeholder="Browse by career area, brand or location" type="text" value={title} onChange={(e) => {setTitle(e.target.value)}}/>
              </div>
          </div>
          <button type="submit" className="searchForm__submit a-button--black">
              <i className="a-icon-search" aria-hidden="true"/>
              <p>Search</p>    
          </button>
          <button type="button" onClick={() => handleAdvanced()} className="searchForm__toggleFilter a-button--black-border ">
            <i className="a-icon a-icon-refine-m"/>
            <p>Advanced Search</p>
          </button>
      </div>

      <div className={`searchForm__advanced m-form-row container ${showAdvanced ? 'searchForm__advanced--open' : ''}`}>
          <div className="m-field-wrapper select">
              <label className="a-legend label">Type:</label>
              <div className="m-form-field m-form-select-wrapper">
                  <select  value={queryType} onChange={(e) =>  {setQueryType(e.target.value); handleSubmit({id: "type", value: e.target.value})}} type="text" name="type">
                    <option  value={0}>All types</option>
                    {filters.types.map((i,key) => (
                      <option value={i} key={key}>{i}</option>
                    ))

                    }
                  </select>
              </div>
          </div>

          <div className="m-field-wrapper select">
              <label className="a-legend label">Department:</label>
              <div className="m-form-field m-form-select-wrapper">
                  <select value={queryDepartment} onChange={(e) =>  {setQueryDepartment(e.target.value); handleSubmit({id: "department", value: e.target.value})}} name="department" type="text">
                    <option  value={0}>All departments</option>
                    {filters.departments.map((i,key) => (
                      <option value={i} key={key}>{i}</option>
                    ))}
                  </select>
              </div>
          </div>

          <div className="m-field-wrapper select">
              <label className="a-legend label">Country:</label>
              <div className="m-form-field m-form-select-wrapper">
                  <select value={queryCountry} onChange={(e) =>  {setQueryCountry(e.target.value); handleSubmit({id: "country_id", value: e.target.value})}} name="country" type="text">
                    <option  value={0}>All Countries</option>
                    {filters.countries.map((i,key) => (
                      <option value={i} key={key}>{i}</option>
                    ))}
                  </select>
              </div>
          </div>
     
          <div className="m-field-wrapper select">
              <label className="a-legend label">State:</label>
              <div className="m-form-field m-form-select-wrapper">
                  <select value={queryState} onChange={(e) =>  {setQueryState(e.target.value);  handleSubmit({id: "state", value: e.target.value})}} name="state" type="text">
                    <option  value={0}>All States</option>
                    {filters.states.map((i,key) => (
                      <option value={i} key={key}>{i}</option>
                    ))}
                  </select>
              </div>
          </div>
     
      </div>
    </form>

  </div>
  )
}


export default Search;