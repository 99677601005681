import React from "react"
import {
    useParams
  } from "react-router-dom";
import "./styles.scss"
import {brandContent} from "../../assets/brands";
import Header from "../header";
import { GROUPE_LOCCITANE } from "../../assets/variables";


function JobSearchPage(props) {
    let params = useParams()
    let jobBoard = params.jobId;
    const companyPage = (params.companyPage && brandContent[params.companyPage] !== undefined) ? params.companyPage : GROUPE_LOCCITANE;  
    const brand =  brandContent[companyPage];

    return (
      <div className="jobPage">
        <div className="jobContent">
        <Header brand={brand} companyPage={companyPage} filters={props.filters} setJobs={props.setJobs}/>

        <iframe title="Job Page" id="searchPageIframe" src={`https://loccitaneenprovence.applytojob.com/apply/${jobBoard}`}></iframe>
        </div>
      </div>
    )
}

export default JobSearchPage;

  