import React from 'react';
import './styles.scss';

import {
    Link,
  } from "react-router-dom";

function Job(props) {

 
  // <div dangerouslySetInnerHTML={{__html: jobInfo.description}}></div>

    let jobInfo = props.info;
    let jobURL = "https://loccitaneenprovence.applytojob.com/apply/" + jobInfo.board_code;

    return (
      <Link className='jobCard' data-link={jobURL} to={props.companyPage ? `/${props.companyPage}/jobs/${jobInfo.board_code}` : `/jobs/${jobInfo.board_code}`}>
            <span className='jobCard__text'>
                <p><strong>{jobInfo.title} - {jobInfo.type}</strong></p>
                <span>
                    <i className="a-icon-locator" aria-hidden="true"/>
                    {jobInfo.city}, {jobInfo.state}
                </span>
            </span>


      </Link>
    )
}

export default Job;
  