import React from "react";
import './styles.scss'
import { useLocation, useNavigate  } from "react-router-dom";
import SearchJob from "../searchJob";

function Header(props) {
    
    const brand = props.brand;
    const navigate = useNavigate();
    const location = useLocation()

    return(
        <header>
            <nav style={brand && {'background': brand.color}} className="nav">
                <div className="container">
                    {(location.pathname !== '/' || location.search.length) !== 0 &&
                        <button className="goback a-button--text" style={{"color": brand  ? brand.textColor : ""}} onClick={() => navigate(-1)}>
                            <i className="a-icon-chevron-left"></i>
                            
                            Go back
                        </button>
                    }
                    {props.companyPage &&
                    <a style={{"color": brand.textColor}} target="_blank" rel="noreferrer" href={brand.url} className="weblink a-button--text">
                        Visit our website

                        <i className="a-icon-chevron-right"></i>
                    </a>}
                </div>
        
            </nav>
            <img className={`logo ${brand.id}`}
                 src={brand.image} 
                 alt={brand.name}/>

         <SearchJob hideDescription={props.hideDescription} atHome={props.atHome} companyPage={props.companyPage} setJobs={props.setJobs}/>

        </header>
    )
}

export default Header;