import React, {useState, useEffect} from "react";
import "./styles.scss";
import {brandNav, brandContent} from "../../assets/brands";
import JobList from "../jobList";
import { Link } from "react-router-dom";
import Header from "../header";
import { useParams, useSearchParams } from "react-router-dom";
import { useLocationChange } from "../../helpers/customHooks";
import { GROUPE_LOCCITANE } from "../../assets/variables";

function Home(props) {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const [jobs, setJobs] = useState([]);
    const [notFound, setNotFound] = useState(false)
    const [hiddenDescription, setHiddenDescription] = useState(false)
    const companyPage = (params.companyPage && brandContent[params.companyPage] !== undefined) ? params.companyPage : GROUPE_LOCCITANE;  
    const brand =  brandContent[companyPage];

    let jobUrl = "/jobs"
    if(searchParams.toString().length) {
      jobUrl += `?${searchParams.toString()}`
    } else if(params.companyPage) {
      jobUrl += `?brand=${params.companyPage}`
    }

    useEffect(() => {
      setHiddenDescription(!!searchParams.toString().length);
    }, [searchParams])

    useLocationChange((location, prevLocation) => {
        if(prevLocation && location.pathname !== prevLocation.pathname) {
          setJobs([])
          fetch(jobUrl)
          .then((res) => res.json())
          .then((data) => {
            if(data.jobs.length) {
              setJobs(data.jobs);  
              setNotFound(false)

            } else {
              setNotFound(true)
            }
            setHiddenDescription(false);
          });
      
        }
    })
  
    useEffect(( ) => {
   
      fetch(jobUrl)
      .then((res) => res.json())
      .then((data) => {
        if(data.success) {
          if(data.jobs.length) {
            setJobs(data.jobs);  
            setNotFound(false)

          } else {
            setJobs([])
            setNotFound(true)
          }
        }
      });
  
    }, [jobUrl])


    return  (
        <div className="home">
          <div className="home__container">
            <Header atHome={true} brand={brand} companyPage={companyPage} hideDescription={() => setHiddenDescription(true)} setJobs={setJobs}/>

            <div className={`brands ${hiddenDescription && companyPage !== GROUPE_LOCCITANE ? 'brands--hidden' : ''}`}>
                <div className={`brands__banner container ${hiddenDescription ? "brands__banner--hidden" : ''}`}>
                    {companyPage ? <img src={brandNav[companyPage].banner} alt=""/> : <img src={brandNav[GROUPE_LOCCITANE].banner} alt=""/>}
                    <p>Careers</p>
                </div>
               {companyPage === GROUPE_LOCCITANE && <div className="brands__row container">
                    {Object.keys(brandNav).map((b, key) =>   brandNav[b].display && (
                      
                        <Link to={`/${brandNav[b].urlParam}`} key={key} className="brand a-button--black-border ">
                             <img src={brandNav[b].image} alt={brandNav[b].name}/>
                        </Link>
                    ))}
                </div>}

                  
                {hiddenDescription && 
                  <button title="Hide Company Information" onClick={() => setHiddenDescription(false)} className="expandButton">
                        <span className="a-icon-chevron-down-m"></span>
                  </button>  
                }
                
            </div>
            <div className="container">
                    <div className={`groupDescription  ${hiddenDescription ? "groupDescription--hidden" : ''}`}>
                        <div className="groupDescription__text">
                            {companyPage  ? <div dangerouslySetInnerHTML={{__html: brandNav[companyPage].text}}/> : <div dangerouslySetInnerHTML={{__html: brandNav[GROUPE_LOCCITANE].text}}/>}
                        </div>

                        {companyPage  ?
                        <iframe width="500" height="400" key={companyPage} src={brandNav[companyPage].video_url} title={brandNav[companyPage].video_title} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    
                          :
                        <iframe width="500" height="400"  key={GROUPE_LOCCITANE} src={brandNav[GROUPE_LOCCITANE].video_url} title={brandNav[GROUPE_LOCCITANE].video_title} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

                        }
                        </div>

                <JobList notFound={notFound} companyPage={companyPage} jobs={jobs}/> 
            </div>
        </div>
          <footer>
            <div className="container-notice">
              <div>
                  <p>
                    In compliance with the Transparency in Coverage Rule, our medical plan providers have made the Machine-Readable Files available to consumers. These files contain information on the costs of 500 common items and services.<br/><br/> 
                    Aetna, Triple-S Salud, and HMSA creates and publishes the Machine-Readable Files on behalf of L’Occitane. To link to the Machine-Readable Files, please click on the URL provided for: <a href="https://salud.grupotriples.com/en/transparency-in-coverage-machine-readable-files/" target={'_blank'}>Triple-Salud</a>; <a href="https://www.hmsa.com/help-center/transparency-in-coverage-machine-readable-files/" target={'_blank'}>HMSA</a>; <a href="https://health1.aetna.com/app/public/#/one/insurerCode=AETNACVS_I&brandCode=ALICSI/machine-readable-transparency-in-coverage?reportingEntityType=Third%20Party%20Administrator_154895&lock=true.)" target={'_blank'}>Aetna</a>
                  </p>
                </div>
            </div>
            
            <div className="container-footer">
                <div className="container-footer-images">
                  {brand.footerImages.map((i, key) => 
                      <img key={key} src={i.src} alt={i.alt}/>
                  )}
                </div>
                <div>
                  <p className="footer-privacy-reserved">
                    {brand.footerText}
                  </p>
                  <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
                </div>
            </div>
          
          </footer>
        </div>
    )
}

export default Home;