import React from 'react';
import Job from '../job';
import './styles.scss'
import {MagnifyingGlass} from  'react-loader-spinner'

function JobList({jobs, notFound, companyPage}) {
  

    if(jobs.length) {
      return (
      <div className='jobList'>
          {jobs.map((i, key) =>
              <Job companyPage={companyPage} key={key} info={i}/>
          )}
      </div>
      )
    }
    if(notFound) {
      return (
        <div className='noResult'>
          <p>No jobs match your search at this time. Browse new opportunities with our other brands.</p>
        </div>
      )
    }

    return (
      <div className='searchWrapper'>
          <MagnifyingGlass
          visible={true}
          height="60"
          width="60"
          ariaLabel="MagnifyingGlass-loading"
          wrapperStyle={{width: '100%'}}
          wrapperClass="MagnifyingGlass-wrapper"
          glassColor = '#c0efff'
          color = '#e15b64'
        />
        <p>Searching for jobs</p>
      </div>
    );

  }

export default JobList;